import HomeSection from "../pageSections/home.section";
import { getCategories_req, getItems_req } from "../api/home.api";
import { getDeviceData } from "../helpers/detector.helper";

function HomePageWithCategoryKey({ isMobile, categories, items }) {
  return (
    <HomeSection categories={categories} items={items} isMobile={isMobile} />
  );
}

HomePageWithCategoryKey.getInitialProps = async function ({ query, req }) {
  let categories = null;
  let items = null;

  const { isMobile } = getDeviceData(req);

  try {
    categories = await getCategories_req({});
  } catch (e) {
    console.log(
      "HomePageWithCategoryKey.getInitialProps -> getCategories_req Error:",
      e
    );
  }

  if (categories && categories.length) {
    try {
      items = await getItems_req({}, query.categoryKey);
    } catch (e) {
      console.log(
        "HomePageWithCategoryKey.getInitialProps -> getItems_req Error:",
        e
      );
    }
  }

  return {
    isMobile,
    categories,
    items,
  };
};

export default HomePageWithCategoryKey;
